import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/index')));

// utilities routing
const HanhSinhBCTuTap = Loadable(lazy(() => import('views/hanhsinh/BaoCaoTuTap')));
const HanhSinhBCHocNhom = Loadable(lazy(() => import('views/hanhsinh/BaoCaoHocNhom')));
const HanhSinhKQTuTap = Loadable(lazy(() => import('views/hanhsinh/KetQuaTuTap')));
const HanhSinhKQTuTapDetail = Loadable(lazy(() => import('views/hanhsinh/KetQuaTuTapDetail')));
const HanhSinhKQTuHocDetail = Loadable(lazy(() => import('views/hanhsinh/KetQuaTuHocDetail')));
const HanhSinhIndex = Loadable(lazy(() => import('views/hanhsinh/index')));
const HanhSinhKQTuTapQuery = Loadable(lazy(() => import('views/hanhsinh/KetQuaTuTapQuery')));

const HanhSinhBaoCaoTuHoc = Loadable(lazy(() => import('views/hanhsinh/BaoCaoTuHoc')));
const HanhSinhKQBaoCaoTuHoc = Loadable(lazy(() => import('views/hanhsinh/KetQuaTuHoc')));
const DiemKiemTraHanhSinh = Loadable(lazy(() => import('views/hanhsinh/DiemKiemTra')));

const AccountInfo = Loadable(lazy(() => import('views/dashboard/account')));
const DanhSachDiemDanhHS = Loadable(lazy(() => import('views/diemdanh/DanhSachDiemDanhHS')));
const DiemdanhHS = Loadable(lazy(() => import('views/diemdanh/DiemDanhHS')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = (isLoggedIn) => {
    return {
        path: '/',
        element: isLoggedIn ? <MainLayout /> : <Navigate to="/login" />,
        children: [
            {
                path: '/',
                element: <DashboardDefault />
            },
            {
                path: '/',
                children: [
                    {
                        path: 'hanh-sinh-bao-cao-tu-tap',
                        element: <HanhSinhBCTuTap />
                    }
                ]
            },
            {
                path: '/',
                children: [
                    {
                        path: 'hanh-sinh-bao-cao-hoc-nhom',
                        element: <HanhSinhBCHocNhom />
                    }
                ]
            },
            {
                path: '/',
                children: [
                    {
                        path: 'hanh-sinh-ket-qua-tu-tap',
                        element: <HanhSinhKQTuTap />
                    }
                ]
            },
            {
                path: '/',
                children: [
                    {
                        path: 'hanh-sinh-ket-qua-tu-tap/:id',
                        element: <HanhSinhKQTuTapDetail />
                    }
                ]
            },
            {
                path: '/',
                children: [
                    {
                        path: 'hanh-sinh-xuat-ket-qua-tu-tap',
                        element: <HanhSinhKQTuTapQuery />
                    }
                ]
            },
            {
                path: '/',
                children: [
                    {
                        path: 'hanh-sinh-bao-cao-tu-hoc',
                        element: <HanhSinhBaoCaoTuHoc />
                    }
                ]
            },
            {
                path: '/',
                children: [
                    {
                        path: 'hanh-sinh-ket-qua-tu-hoc',
                        element: <HanhSinhKQBaoCaoTuHoc />
                    }
                ]
            },
            {
                path: '/',
                children: [
                    {
                        path: 'hanh-sinh-ket-qua-tu-hoc/:id',
                        element: <HanhSinhKQTuHocDetail />
                    }
                ]
            },
            {
                path: 'hanhsinh',
                children: [
                    {
                        path: ':id',
                        element: <HanhSinhIndex />
                    }
                ]
            },
            {
                path: '/',
                children: [
                    {
                        path: 'diem-kiem-tra',
                        element: <DiemKiemTraHanhSinh />
                    }
                ]
            },
            {
                path: '/',
                children: [
                    {
                        path: 'danh-sach-hanh-sinh-diem-danh',
                        element: <DanhSachDiemDanhHS />
                    }
                ]
            },
            {
                path: '/',
                children: [
                    {
                        path: 'diem-danh-hanh-sinh',
                        element: <DiemdanhHS />
                    }
                ]
            },
            {
                path: '/',
                children: [
                    {
                        path: '/account/info',
                        element: <AccountInfo />
                    }
                ]
            }
        ]
    };
};

export default MainRoutes;
