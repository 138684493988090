import dashboard from './dashboard';
import none from './none';
import hanhsinhs from './hanhsinh';
import banquanly from './banquanly';
import diemdanh from './diemdanh';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = (user) => {
    if (user && user.role === 'NONE') {
        return { items: [none] };
    }

    if (user && (user.role === 'BQL' || user.role === 'ADMIN')) {
        return { items: [dashboard, banquanly, diemdanh] };
    }

    if (user && user.role === 'HSDD') {
        return { items: [dashboard, hanhsinhs, diemdanh] };
    }

    if (user && user.isSupport === 'true') {
        return { items: [dashboard, hanhsinhs, diemdanh] };
    }

    return { items: [dashboard, hanhsinhs] };
};

export default menuItems;
